
import { Component, Vue } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import { getConfigEnv } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {},
})
export default class AppFooter extends Vue {
  expandedLink: any = {};

  userInitial = ''

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  }

  links = [
    {
      name: 'Tasqs',
      to: 'Tasqs',
      imgName: 'format-list.svg',
    },
    {
      name: 'FormMobileSubmission',
      to: 'FormMobileSubmission',
      imgName: 'tasq-forms.svg',
    },
    {
      name: 'Users',
      to: 'UserProfile',
      imgName: 'user-profile.svg',
      children: [],
    },
  ];

  isSidebarOpen = true;

  get userInitals() {
    return getNameByEmail(workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0));
  }

  get currentName() {
    return getNameByEmail(workflowModule.user.email);
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  handleSidebarItemClick(link) {
    if (this.expandedLink && link.to === this.expandedLink.to) {
      this.$router.push({
        name: link.to,
      });
      return;
    }
    if (link.children?.length) {
      this.expandedLink = link;
      this.$router.push(link.children[0].to);
      return;
    }
    this.$router.push({
      name: link.to,
    });
  }

  isActiveLink(link) {
    return link.to === this.$route.name
      || this.$route.meta!.parent === link.name;
  }

  toggleSidebarMenu() {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (!this.isSidebarOpen) {
      this.$emit('sidebarClose');
    }
  }
}
